import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../environments/environment';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import 'rxjs/Rx';
import 'rxjs-compat/add/operator/catch';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    baseUrl = environment.baseUrl;
    tokenStatus;
    userImage = null;
    User = new BehaviorSubject(null);
    user = this.User.asObservable();
    
    Queue = new BehaviorSubject(null);
    getQueue = this.Queue.asObservable();


    public messageSource = new BehaviorSubject(0);
    currentMessage = this.messageSource.asObservable();
    
    constructor(private http: HttpClient, public router: Router, public toast: ToastrService) {
        
    }
    private subject = new Subject<any>();
    refreshTokenData() {
        this.subject.next();
      }
      getClickEvent(): Observable<any>{ 
        return this.subject.asObservable();
      }

    getAppJsonHeader() {
        let reqHeader = new HttpHeaders({'Content-Type': 'application/json'});
        return {
            headers: reqHeader
        };
    }

    getTokenHeader(token?) {
        if (!token) {
            let t = JSON.parse(localStorage.getItem('otClient'));
            token = t && t.userAuth ? t.userAuth : '';
        }
        let reqHeader = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        return {
            headers: reqHeader

        };
    }

    getTokenKisokHeader(token?) {
        let reqHeader = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token});
        return {
            headers: reqHeader
        };
    }

    getTokenHeaderText(token?) {
        if (!token) {
            let t = JSON.parse(localStorage.getItem('otClient'));
            token = t.userAuth;
        }
        let reqHeader = new HttpHeaders({'Content-Type': 'text/html; charset=UTF-8', Authorization: 'Bearer ' + token});
        return {
            headers: reqHeader
        };
    }

    getTokenHeaderPassed(token) {
        let reqHeader = new HttpHeaders({'Content-Type': 'application/json', Authorization: 'Bearer ' + token});
        return {
            headers: reqHeader
        };
    }

    getAcccessTokens() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic YnVzaW5lc3NMb2dpbjo='
        });
        return {
            headers: headers
        };
    }

    static get SyncLocalStorage() {
        return JSON.parse(localStorage.getItem('otClient'));
    }

    public static isProfileCompleted(): boolean {
        return ApiService.SyncLocalStorage.registerComplete;
    }

    public isSettingCompleted(): boolean {
        if (ApiService.SyncLocalStorage.configComplete && ApiService.SyncLocalStorage.serviceConfigComplete) {
            if (ApiService.SyncLocalStorage.details.status === 'EXPIRED') {
                return false;
            } else {
                return true;
            }
        }
    }


    public isAuthenticated(): boolean {
        return !!ApiService.SyncLocalStorage;

    }

    searchClients(data): Observable<any> {
        return this.http.post(this.baseUrl + 'user/searchClients', data, this.getAppJsonHeader());
    }

    getAvailableClientAppointment(data): Observable<any> {
        return this.http.post(this.baseUrl + 'appoint/client/available', data, this.getAppJsonHeader());
    }

    preFetch(data): Observable<any> {
        return this.http.post(this.baseUrl + 'appoint/client/next/fetch', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getClientService(clientId): Observable<any> {
        return this.http.get(this.baseUrl + `client/services/${clientId}`);
    }

    validateOtpRequest(data): Observable<any> {
        return this.http.post(this.baseUrl + 'client/queue/validateOTP', data, this.getTokenHeader(null)).catch(x => this.handleAuthError(x));
    }


    addAppointment(data, token): Observable<any> {
        return this.http.post(this.baseUrl + 'appoint/client/add', data, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    addPrequeue(data, token): Observable<any> {
        return this.http.post(this.baseUrl + 'appoint/prequeue/add', data, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    addAppointmentkisok(data, token): Observable<any> {
        return this.http.post(this.baseUrl + 'appoint/client/add', data, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    getTVList(id): Observable<any> {
        return this.http.get(this.baseUrl + 'client/activeTokenInst/' + id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getTVListWaitlist(id, token): Observable<any> {
        return this.http.get(this.baseUrl + 'client/tvView/' + id, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    getClientTime(id): Observable<any> {
        return this.http.get(this.baseUrl + 'user/client/waitTime/' + id).catch(x => this.handleAuthError(x));
    }

    toggleTokenStatus(data, tokenStatus): Observable<any> {
        let url = tokenStatus === 'ACTIVE' ? 'closeBusiness' : 'resumeBusiness';
        return this.http.put(this.baseUrl + 'client/' + url + '/', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    pauseUnPause(data, tokenStatus): Observable<any> {
        let url = tokenStatus === 'PAUSED' ? 'resumeBusiness' : 'pauseBusiness';
        return this.http.put(this.baseUrl + 'client/' + url + '/', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    addWebDesc(data): Observable<any> {
        return this.http.put(this.baseUrl + 'client/webSiteAndDesc', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getWebDesc(data): Observable<any> {
        return this.http.get(this.baseUrl + 'client/webSiteAndDesc/' + data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    toggleTokenHold(data): Observable<any> {
        return this.http.post(this.baseUrl + 'token/onHoldToken', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    toggleMiniTokenStatus(data, tokenStatus): Observable<any> {
        let url = tokenStatus === 'ACTIVE' ? 'pauseForDay' : 'resumeForDay';
        return this.http.put(this.baseUrl + 'client/pauseForDay/', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    changePassword(data, token): Observable<any> {
        return this.http.put(this.baseUrl + 'user/changepassword', data, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    addECheckIn(data, token): Observable<any> {
        return this.http.put(this.baseUrl + 'token/nextClientToken', data, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    setNotificationSetting(data): Observable<any> {
        return this.http.post(this.baseUrl + 'user/mobileUserNotifications', data, this.getTokenHeader());
    }

    getLang(): Observable<any> {
        return this.http.get(this.baseUrl + 'services/supported/languages', this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    getLangData(code): Observable<any> {
        return this.http.get(this.baseUrl + 'services/language/business/' + code, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    checkActiveToken(id, token): Observable<any> {
        return this.http.get(this.baseUrl + 'user/booking/validate/' + id, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    getChildServices(data): Observable<any> {
        return this.http.post(this.baseUrl + 'parent/childServices', data, this.getTokenHeader(null)).catch(x => this.handleAuthError(x));
    }

    cancelToken(data): Observable<any> {
        return this.http.post(this.baseUrl + 'token/cancelToken', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }


    deleteUser(): Observable<any> {
        return this.http.delete(this.baseUrl + 'user/delete', this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    editProfile(data): Observable<any> {
        return this.http.post(this.baseUrl + 'user/mobileUserProfile', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    //////////////////////////////////

    login(data): Observable<any> {
        const requestBody = `grant_type=password&username=${data.email}&password=${data.password}`;
        return this.http.post(this.baseUrl + 'oauth/token', requestBody, this.getAcccessTokens());
    }

    getUserDetail(data, token): Observable<any> {
        console.log(token);
        return this.http.post(this.baseUrl + 'user/getUser', data, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    getUserDetailKisok(data, token): Observable<any> {
        console.log(token);
        return this.http.post(this.baseUrl + 'user/getUser', data, this.getTokenKisokHeader(token)).catch(x => this.handleAuthError(x));
    }

    signUp(data) {
        return this.http.put(this.baseUrl + 'user/register', data, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    resendMailVerification(data) {
        return this.http.put(this.baseUrl + 'user/resendActivation', data, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    verifyEmail(token) {
        return this.http.put(this.baseUrl + 'user/activate', {}, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    registerClient(body) {
        return this.http.put(this.baseUrl + 'client/create', body, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    applyPromoCode(body) {
        return this.http.post(this.baseUrl + 'client/promoCode', body, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getAllServiceTypes() {
        return this.http.get(this.baseUrl + 'client/businessTypes', this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    getQRCode(id) {
        return this.http.get(this.baseUrl + 'client/qrCode/html/' + id, this.getTokenHeaderText()).catch(x => this.handleAuthErrorText(x));
    }

    getPDF(id) {
        return this.http.get(this.baseUrl + 'client/qrCode/pdf/' + id, this.getTokenHeaderText()).catch(x => this.handleAuthErrorText(x));
    }

    getClientHours(id) {
        return this.http.get(this.baseUrl + 'client/hours/' + id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getClientHolidays(id) {
        return this.http.get(this.baseUrl + 'client/holidays/' + id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getClientBusiness(id) {
        return this.http.get(this.baseUrl + 'client/address/' + id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }


    getwalkInAndAppoint(id) {
        return this.http.get(this.baseUrl + 'client/walkInAndAppoint/' + id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getUserclient(data, token) {
        return this.http.post(this.baseUrl + 'client/userClient', data, this.getTokenHeader(token)).catch(x => this.handleAuthError(x));
    }

    moveTokenToDestQueue(data) {
        return this.http.post(this.baseUrl + 'client/transferToken', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getDestAllQueueStatus(containerId) {
        return this.http.get(this.baseUrl + 'client/availableDestQueues/'+ containerId, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    updateClientHours(value: any) {
        return this.http.put(this.baseUrl + 'client/addOrUpdateHours', value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    addCapacityServer(value: any) {
        return this.http.put(this.baseUrl + 'client/addCapacity', value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    minusCapacityServer(value: any) {
        return this.http.put(this.baseUrl + 'client/minusCapacity', value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    updateClientHolidays(value: any) {
        return this.http.put(this.baseUrl + 'client/addOrUpdateHolidays', value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    addQueue(value: any) {
        return this.http.put(this.baseUrl + 'client/addQueue', value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    deleteQueue(value: any) {
        return this.http.delete(this.baseUrl + 'client/deleteQueue/' + value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    editQueue(value: any) {
        return this.http.post(this.baseUrl + 'client/editQueue', value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    addQueueUser(value: any) {
        return this.http.put(this.baseUrl + 'client/queue/addUser', value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    sendOtpRequest(data): Observable<any> {
        return this.http.put(this.baseUrl + 'user/validate/sendOTP', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getUserDetailViaPhone(value: any) {
        return this.http.get(this.baseUrl + 'user/mobile/details/' + value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getCompletedTokens(value: any) {
        return this.http.get(this.baseUrl + 'client/completedTokenInst/' + value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getTokenStatus(data): Observable<any> {
        return this.http.post(this.baseUrl + 'token/lightTokenStatus', data, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    verifyUserToken(token): Observable<any> {
        return this.http.get(this.baseUrl + `user/validate/oAuth`, this.getTokenKisokHeader(token)).catch(x => this.handleAuthErrorKisok(x));
    }

    getcanceledTokens(value: any) {
        return this.http.get(this.baseUrl + 'client/cancelledTokenInst/' + value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    notifyToken(data) {
        return this.http.post(this.baseUrl + 'token/notify', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    walkInAndAppoint(value: any) {
        return this.http.put(this.baseUrl + 'client/walkInAndAppoint', value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    addService(value: any) {
        return this.http.put(this.baseUrl + 'client/addOrUpdate/services', value, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    deleteService(id: any) {
        return this.http.delete(this.baseUrl + 'client/deleteById/' + id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    deleteStaff(id: any, nick) {
        return this.http.delete(this.baseUrl + 'client/staff/remove/' + id + '/' + nick, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getPaypalAllPlanIds(code: string) {
        return this.http.get(this.baseUrl + 'payment/paypal/planIds/' + code, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getPaypalAllPlanIdsPublic(code: string) {
        return this.http.get(this.baseUrl + 'payment/paypal/planIds/' + code, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }


    getService(id: any) {
        return this.http.get(this.baseUrl + 'client/services/' + id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    forgotPassword(data: any) {
        return this.http.put(this.baseUrl + 'user/forgetpassword', data, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    addStaffMember(data: any) {
        return this.http.put(this.baseUrl + 'client/staff/addOrUpdate', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getStaffList(id: any) {
        return this.http.get(this.baseUrl + 'client/staff/list/' + id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getDashUpdates(id: any) {
        return this.http.get(this.baseUrl + 'client/businessDashUpdate/' + id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    addImage(data) {
        return this.http.put(this.baseUrl + 'client/attach', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    addShortcode(data) {
        return this.http.put(this.baseUrl + 'client/shortCode', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    addProfile(data) {
        return this.http.put(this.baseUrl + 'client/attach', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    removeProfile(data) {
        return this.http.post(this.baseUrl + 'client/remove/attach', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }


    getAttached(data) {
        return this.http.post(this.baseUrl + 'client/getAttachData', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    expireToken(data) {
        return this.http.post(this.baseUrl + 'token/expireToken', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    startToken(req) {

        return this.http.post(this.baseUrl + 'token/startToken', req, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    updateToken(req) {
        return this.http.post(this.baseUrl + 'token/updateToken', req, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    addNewToken(data) {
        return this.http.put(this.baseUrl + 'token/nextToken', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getBookedData(data) {
        return this.http.post(this.baseUrl + 'appoint/client/booked/day', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    updateAddress(data) {
        return this.http.put(this.baseUrl + 'client/update/address', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getPairCode(id) {
        return this.http.get(this.baseUrl + 'user/pairCode/' + id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    validateKiosk(pairCode) {
        return this.http.get(this.baseUrl + 'user/validateCode/kiosk/' + pairCode, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    validateTv(pairCode) {
        return this.http.get(this.baseUrl + 'user/validateCode/tv/' + pairCode, this.getAppJsonHeader()).catch(x => this.handleAuthError(x));
    }

    getCustomerList(id) {
        return this.http.post(this.baseUrl + 'client/customer/' + id.clientId, id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getCustomerHistory(id) {
        return this.http.post(this.baseUrl + 'client/customer/history', id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getChartData(data) {
        return this.http.post(this.baseUrl + 'client/waitTimeChart', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    deleteCuHistory(data) {
        return this.http.post(this.baseUrl + 'client/customer/delete/history', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getPaypalList(data) {
        return this.http.post(this.baseUrl + 'payment/client/paymentHistory', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getRazorSubscriptionID(data) {
        return this.http.post(this.baseUrl + 'payment/razor/createSubscription', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    cancelSubscription(data) {
        return this.http.post(this.baseUrl + 'payment/cancelSubscription', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    createRazorOrder(data) {
        return this.http.post(this.baseUrl + 'payment/razor/createOrder', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    cancelAllAppointment(data) {
        return this.http.put(this.baseUrl + 'appoint/client/cancel/day', data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    cancelAppointment(id) {
        return this.http.delete(this.baseUrl + 'appoint/deleteById/' + id, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    comToken(req) {
        return this.http.post(this.baseUrl + 'token/completeToken', req, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    savePaypalSubscription(param) {
        return this.http.post(this.baseUrl + `payment/client/saveSubscription`, param, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    saveSMSCredit(param) {
        return this.http.post(this.baseUrl + `payment/paypal/client/addSMSCredit`, param, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    searchChild(data) {
        return this.http.post(this.baseUrl + `parent/searchChild`, data, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    serveAppointment(body) {
        return this.http.post(this.baseUrl + 'token/ondemandtoken', body, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }

    getIButtonHtml(type, clientId) {
        console.log(type,"Type");
        
        return this.http.get(this.baseUrl + `client/iButton/${type}/${clientId}`, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }
    getIFrameHtml(type, clientId) {
        return this.http.get(this.baseUrl + `client/iFrame/${type}/${clientId}`, this.getTokenHeader()).catch(x => this.handleAuthError(x));
    }
    
    announceToken(data) {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        return this.http.post(this.baseUrl + `token/announceToken`, data, { headers }).catch(x => this.handleAuthError(x));
    }

    textToSpeech(text: string, selectedVoice?: SpeechSynthesisVoice) {
        return new Promise((resolve, reject) => {
            const utterance = new SpeechSynthesisUtterance(text);
            if(selectedVoice) {
                utterance.voice = selectedVoice;
            }
            utterance.onend = resolve; // Resolves the promise when speech ends
            speechSynthesis.speak(utterance);
        });
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {
            localStorage.removeItem('otClient');
            this.router.navigate(['/']);
        } else {
            return Observable.throw(err.error);
        }
    }

    private handleAuthErrorKisok(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {
            localStorage.removeItem('ot.kiosk.loginToken');
            location.reload()
        } else {
            return Observable.throw(err.error);
        }
    }

    private handleAuthErrorText(err: HttpErrorResponse): Observable<any> {
        return Observable.throw(err.error.text);

    }


}
