import {BrowserModule} from '@angular/platform-browser';
import {NgModule, Provider, Type} from '@angular/core';
import {AppComponent} from './app.component';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RouterModule, Routes} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../environments/environment';
import {FullAccessGuard} from './routeGuards/full-access.guard';

const appRoutes: Routes = [
    {
        path: 'dashboard', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    },
    {
        path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: 'tv-view',
        loadChildren: () => import('./tv-view/tv-view.module').then(m => m.TvViewModule),
        canActivate: [FullAccessGuard]
    },
    {
        path: 'kiosk',
        loadChildren: () => import('./kisok/kisok.module').then(m => m.KisokModule)
    },
    {
        path: 'tv-waitlist',
        loadChildren: () => import('./tv-waitlist/tv-waitlist.module').then(m => m.TvWaitlistModule)
    },
    {
        path: 'explore',
        loadChildren: () => import('./layout/parent/parent.module').then(m => m.ParentModule),
        canActivate: [FullAccessGuard]
    },
    {
        path: 'resources',
        loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule)
    },
    {
        path: 'restricted', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: 'pricing', loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule)
    },
    {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full'
    }
];


declare module '@angular/core' {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleApi
        }),
        RouterModule.forRoot(appRoutes),
        ToastrModule.forRoot({timeOut: 3000, positionClass: 'toast-top-right', preventDuplicates: true}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    bootstrap: [AppComponent],
    exports: [CommonModule]
})
export class AppModule {
}



