export const environment = {
    production: false,
    baseUrl: 'https://api-dev.onlinetoken.co/v1/',
    //baseUrl: 'http://localhost:9090/v1/', 
    clientURL: 'https://app-dev.onlinetoken.co/',
    landingPageUrl: 'https://dev.onlinetoken.co/',
    businessNeatURL: 'https://portal.onlinetoken.co/',
    businessURL: 'https://portal.onlinetoken.co/auth/signup',
    selfURL: 'https://portal-dev.onlinetoken.co/',
    googleApi: 'AIzaSyAviR6WpNEAQluRDuESzvCthP34N8NgZOk',
    paypalKey: 'AVKB3bkueStucqdupMlM8TRbdv8EIZDR81LfbhD0v5VQV5WpRHMbunHJOuYCJbkLGJIa9zHlK6hyn1be',
    stagingClient: 'https://app-dev.onlinetoken.co/portal/'
};

