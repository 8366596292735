import {Component, OnInit} from '@angular/core';
import {TokenAnimations} from './Animations/qaar-animations';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: TokenAnimations
})
export class AppComponent implements OnInit {

    loading = false;

    constructor(public translate: TranslateService, private router: Router) {
        translate.addLangs(['en']);
        translate.setDefaultLang('en');
    }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }


}
