import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {ApiService} from '../api.service';

@Injectable({
    providedIn: 'root'
})
export class FullAccessGuard implements CanActivate {
    constructor(public apiService: ApiService, public router: Router) {
    }

    canActivate(): boolean {
        if (!this.apiService.isAuthenticated()) {
            console.log('not loggedin');
            this.router.navigate(['/auth/login']);
            return false;
        } else if (!ApiService.isProfileCompleted()) {
            console.log('Profile not valid');
            this.router.navigate(['/auth/complete-profile']);
            return false;
        } else if (!this.apiService.isSettingCompleted()) {
            console.log('Setting not valid');
            this.router.navigate(['/dashboard/setting']);
            return false;
        } else {
            return true;
        }

    }

}
